import React from 'react'

import Header from './components/Header'
import Footer from './components/Footer'
import Hero from './components/Hero'
import How from './components/How'
import About from './components/About'
import Partner from './components/Partner'
import Stores from './components/Stores'
import Media from './components/Media'
import Franchise from './components/Franchise'

export default class App extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Hero />
        <How />
        <About />
        <Stores />
        <Partner />
        <Franchise />
        <Media />
        <Footer />
      </>
    )
  }
}
