import React from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { v4 as uuid } from 'uuid'

import hero1 from '../img/hero1.jpg'
import hero2 from '../img/hero2.jpg'
import hero3 from '../img/hero3.jpg'
import hero4 from '../img/hero4.jpg'
import { scrollToDiv } from './Utils'

export default function Hero() {
  const controls = useAnimation()

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.3, duration: 0.8 },
    }))
  })

  const pictureArray = [
    {src: hero1, title: "mjölken"},
    {src: hero2, title: "smöret"},
    {src: hero3, title: "saltet"},
    {src: hero4, title: "kaffet"}
  ]

  const randomIndex = Math.floor(Math.random() * pictureArray.length);
  const selectedPicture = pictureArray[randomIndex];

  const herocontents = (
    <MainTitleHolder>
      <motion.ul>
        <MainTitle
          custom={0}
          animate={controls}
          initial={{ opacity: 0, y: 20 }}
          key={uuid()}
        >
          <h2>Glömde du {selectedPicture.title}?</h2>
        </MainTitle>
        <MainTitle
          custom={1.75}
          animate={controls}
          initial={{ opacity: 0, y: 20 }}
          key={uuid()}
        >
          <p>
            Lugn, 24food ska finnas nära. Vi har öppet dygnet runt, 365 dagar om
            året! I våra butiker hittar du det mesta för middagen och hemmet.
          </p>
        </MainTitle>
        <MainTitle
          custom={3.5}
          animate={controls}
          initial={{ opacity: 0, y: 20 }}
          key={uuid()}
        >
          <p>Välkommen in i din närmsta butik!</p>
        </MainTitle>
      </motion.ul>
    </MainTitleHolder>
  )

  return (
    <>
      {isMobile && (
        <MobileHeroDiv style={{ backgroundImage: `url(${selectedPicture.src})` }}>
          {herocontents}
          <DownArrow
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -12, rotate: '45deg' }}
            transition={{ delay: 2.5, duration: 1 }}
            onClick={() => {
              window.scroll({
                top: window.innerHeight - 58,
                left: 0,
                behavior: 'smooth',
              })
            }}
          />
        </MobileHeroDiv>
      )}
      {!isMobile && (
        <HeroDiv style={{ backgroundImage: `url(${selectedPicture.src})` }}>
          {herocontents}
          <DownArrow
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -12, rotate: '45deg' }}
            transition={{ delay: 2.5, duration: 1 }}
            onClick={() => {
              scrollToDiv('shop')
            }}
            style={{ cursor: 'pointer' }}
          />
        </HeroDiv>
      )}
    </>
  )
}

const MobileHeroDiv = styled.div`
  width: 100vw;
  height: 100.1vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeroDiv = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 769px) {
    margin-top: 0;
  }

  > img {
    margin-top: 10%;
  }
`

const MainTitleHolder = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainTitle = styled(motion.li)`
  color: #fff;
  text-align: center;
  opacity: 0;
  font-size: 24px;
  line-height: 28px;

  @media (min-width: 769px) {
    font-size: 40px;
    line-height: 42px;
  }

  p {
    width: 80%;
    margin: 50px auto;
    font-size: 22px;
    line-height: 28px;

    @media (min-width: 769px) {
      width: 60%;
    }
  }
`

const DownArrow = styled(motion.div)`
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 15px);
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
`
