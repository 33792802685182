import styled from 'styled-components'
import React from 'react'

import Menu from './Menu'
import logo from '../img/24food-logo.png'

function Header() {
  return (
    <HeaderContainer>
      <HeaderDiv>
        <LogoHolder>
          <HeaderLogo>
            <img
              alt="Logo"
              src={logo}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              style={{ cursor: 'pointer' }}
            />
          </HeaderLogo>
        </LogoHolder>
        <DesktopMenuHolder>
          <Menu />
        </DesktopMenuHolder>
      </HeaderDiv>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  height: 60px;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;

  @media (min-width: 769px) {
    height: 80px;
  }
`

const HeaderDiv = styled.div`
  margin: 0 auto;
  width: 980px;
  max-width: calc(100% - 40px);
  height: 100%;
  display: flex;
  position: relative;
`

const LogoHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;

  @media (min-width: 769px) {
    position: relative;
    width: auto;
  }
`

const HeaderLogo = styled.div`
  text-align: center;
  margin-top: 11px;

  @media (min-width: 769px) {
    margin: 0;
  }

  img {
    cursor: pointer;
    height: 40px;
  }
`

const DesktopMenuHolder = styled.div`
  width: 100vw;

  @media (min-width: 769px) {
    position: relative;
    max-width: 980px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
  }
`

export default Header
