import React from 'react'
import styled from 'styled-components'

import { MainTextHolder } from './Utils'

import na from '../img/stores/na.png'

import ekebyalmby from '../img/stores/ekebyalmby.jpeg'
import lanna from '../img/stores/lanna.jpeg'
import karlsborg from '../img/stores/karlsborg.jpeg'
import lillan from '../img/stores/lillan.jpeg'
import morko from '../img/stores/morko.jpeg'
import edsvalla from '../img/stores/edsvalla.jpeg'
import liljedal from '../img/stores/liljedal.jpeg'
import vimmerby from '../img/stores/vimmerby.jpeg'
import askersund from '../img/stores/askersund.jpeg'
import horvik from '../img/stores/horvik.jpeg'
import nogersund from '../img/stores/nogersund.jpeg'
import vena from '../img/stores/vena.jpeg'
import branno from '../img/stores/branno.jpeg'
import sjulsmark from '../img/stores/sjulsmark.jpg'

import franchisebanner from '../img/franchisebanner.png'

type StoreProps = {
  stores: [
    {
      name: string
      address: string
      lat: string
      lng: string
      text: string
      image: string
      hours: string
      featuredProduct: string
      franchise: boolean
    }
  ]
}

export default class Stores extends React.Component<StoreProps> {
  static defaultProps = {
    stores: [
      {
        name: 'Örebro - Lillån',
        address: '24Food+Lillån/@59.3156627,15.2211224,17z/data=!3m1!4b1!4m5!3m4!1s0x465c153b51242917:0xe34bf22aabd93861!8m2!3d59.31566!4d15.2233111',
        text: 'Vår första butik i en stadsnära miljö. Varmt välkomna!',
        image: lillan,
        hours: 'Alltid öppet!',
        franchise: true,
      },
      {
        name: 'Örebro - Ekeby-Almby',
        address: '24Food+Ekeby-Almby/@59.2596023,15.3442463,17z/data=!3m1!4b1!4m5!3m4!1s0x465c179a85e5d9c3:0x541eeaf932b31168!8m2!3d59.2595996!4d15.346435',
        text:
          'Butiken i Ekeby Almby är vår första butik. Vi hoppas att du gillar den! Vi har plats för omkring 550 olika produkter och fler är på väg.',
        image: ekebyalmby,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Örebro - Lanna Lodge',
        address: '24Food+Lanna/@59.2177877,14.8881276,12z/data=!4m9!1m2!2m1!1s24+Food+lanna+lodge!3m5!1s0x465c6f3f8fee2975:0xa2f2694c6b0934c2!8m2!3d59.2400302!4d14.9049671!15sChMyNCBGb29kIGxhbm5hIGxvZGdlWhUiEzI0IGZvb2QgbGFubmEgbG9kZ2WSAQ1ncm9jZXJ5X3N0b3Jl',
        text:
          'Butiken i Lanna är vår andra butik och är av vår större modell. Här finns det plats för omkring 1000 produkter.',
        image: lanna,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Karlsborg - Marinan',
        address: '24+Food/@58.5486139,14.5025945,17z/data=!3m1!4b1!4m5!3m4!1s0x465bbb66a54b6877:0xbde5780621c9411e!8m2!3d58.5486127!4d14.5047912',
        text:
          '24Food Karlsborg är vår första franschisebutik. Det är en standardmodell och innehåller ca. 550 artiklar.',
        image: karlsborg,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Mörkö - Mörkö Handelsbod',
        address:
          '24+Food+Mörkö/@58.9928121,17.6526379,17z/data=!3m1!4b1!4m5!3m4!1s0x465f417ca116d985:0x4adee77fdfc9e746!8m2!3d58.9928121!4d17.6526379',
        text:
          '24Food Mörkö Handelsbod är en franchisebutik med ca. 550 artiklar.',
        image: morko,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Edsvalla',
        address: '24Food/@59.4362227,13.2093217,17z/data=!3m1!4b1!4m5!3m4!1s0x46434f571fc2db1d:0xbdbef408026d2036!8m2!3d59.4362204!4d13.2114389',
        text: '24Food Edsvalla är vår första butik i en befintlig byggnad. Möjlig för franchise!',
        image: edsvalla,
        hours: 'Alltid öppet!',
        franchise: true,
      },
      {
        name: 'Vimmerby - Nossen Livs',
        address: '24Food+Nossen+Livs/@57.6620479,15.8982802,17z/data=!3m1!4b1!4m5!3m4!1s0x4659cb8ba0a218f9:0xf02ae4384587260a!8m2!3d57.6620545!4d15.9004048',
        text: '24Food Vimmerby',
        image: vimmerby,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Liljedal - Gästhamnen',
        address: '24Food+Liljedal/@59.2649223,13.0759334,17z/data=!3m1!4b1!4m5!3m4!1s0x464357dbbc9b1e1d:0x6421c731730d1deb!8m2!3d59.2649196!4d13.0781221',
        text: '24Food Liljedal',
        image: liljedal,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Askersund',
        address: '24food+Askersund/@58.8794328,14.9022664,17z/data=!3m1!4b1!4m5!3m4!1s0x465b8da82646933d:0xb70b494096c3f15a!8m2!3d58.8794853!4d14.9044306',
        text: '24Food Askersund',
        image: askersund,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Göteborg - Brännö',
        address: '24Food+Brännö/@57.6479676,11.7818696,17z/data=!3m1!4b1!4m5!3m4!1s0x464f8d7b1529d73f:0xcc73e9e56ab36dae!8m2!3d57.6480002!4d11.7840154',
        text: '24Food Brännö',
        image: branno,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Sölvesborg - Hörvik',
        address: '24Food+Hörvik/@56.04156,14.7618556,17z/data=!3m1!4b1!4m5!3m4!1s0x4656833733695c3f:0xf94704ed40e394e8!8m2!3d56.0414426!4d14.7639911',
        text: '24Food Hörvik',
        image: horvik,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Sölvesborg - Nogersund',
        address: '24Food+Nogersund/@56.0050005,14.7363759,17z/data=!3m1!4b1!4m5!3m4!1s0x4654293be400d165:0x9ffd39e1f4819bff!8m2!3d56.0049975!4d14.7385646',
        text: '24Food Nogersund',
        image: nogersund,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Vena',
        address: 'Kristdalavägen 4 Vena',
        text: '24Food Matlådan i Vena',
        image: vena,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Hällabrottet',
        address: 'Affärsgatan 9 Kumla',
        text: '24Food Hällabrottet',
        image: na,
        hours: 'Alltid öppet!',
        franchise: true,
      },
      {
        name: 'Österåker',
        address: '59°07\'28.0"N+15°59\'15.0"E/@59.1244444,15.9853113,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xb1953037b10af261!8m2!3d59.124432!4d15.98751',
        text: '24Food Österåker',
        image: na,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Sjulsmark',
        address: '24food+Sjulsmark/@65.5169014,21.4937518,17z/data=!3m1!4b1!4m5!3m4!1s0x467f416edb20aba3:0x453ec5845e016a0!8m2!3d65.5168992!4d21.4959405',
        text: '24Food Sjulsmark',
        image: sjulsmark,
        hours: 'Alltid öppet!',
      },
      {
        name: 'Västra Husby',
        text: '24Food Västra Husby',
        image: na,
        hours: 'Alltid öppet!',
      },
      {
          name: 'Västerljung',
          text: '24Food Västerljung',
          image: na,
          hours: 'Alltid öppet!',
      }
    ],
  }

  render() {
    return (
      <div id="stores">
        <StyledMainTextHolder>
          <h3>Några av våra butiker</h3>
          <ul style={{ marginTop: 40 }}>
            {this.props.stores.map(store => {
              const location =
                store.lat && store.lng
                  ? `${store.lat},${store.lng}`
                  : `${store.address}`
              return (
                <StoreHolder>
                  <div style={{ position: 'relative' }}>
                    { store.franchise && <FranchiseBanner src={franchisebanner} /> }
                    <img src={store.image} alt={store.name} />
                  </div>
                  <div>
                    <h5>{store.name}</h5>
                    <p>{store.text}</p>
                    <p>
                      Öppettider: {store.hours}
                      {store.address && <>
                        <br />
                        Adress:{' '}
                        <a
                          href={`https://www.google.com/maps/place/${location}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Klicka för karta
                        </a>
                        </>
                       }
                    </p>
                  </div>
                </StoreHolder>
              )
            })}
          </ul>
        </StyledMainTextHolder>
      </div>
    )
  }
}

const StyledMainTextHolder = styled(MainTextHolder)`
  position: relative;
  z-index: 4;
  margin: 0 auto;

  p {
    font-size: 22px;
    line-height: 28px;
    text-align: left;
  }
`

const StoreHolder = styled.li`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  img {
    width: 100%;

    @media (min-width: 769px) {
      width: 300px;
    }
  }

  & > div {
    text-align: left;

    h5 {
      font-size: 24px;
      margin-top: 20px;
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
    align-items: center;

    & > div {
      margin-left: 40px;

      h4 {
        margin-top: 0;
      }
    }
  }
`

const FranchiseBanner = styled.img`
    width: 210px !important;
    position: absolute;
    left: -11px;
    top: -9px;
`