import React, { useState } from 'react'
import styled from 'styled-components'

import logo from '../img/24food-logo.png'
import appstoreicon from '../img/appstoreicon.svg'
import googleplayicon from '../img/googleplayicon.png'

export default function Footer() {
  const [showGDPRInfo, setShowGDPRInfo] = useState(false)

  return (
    <FooterDivHolder>
      <FooterDiv>
        <FooterLeft>
          <a href="#top">
            <img alt="Logo" src={logo} style={{ width: 150 }} />
            <p>
              Vår vision är att finnas där vi behövs. Vi har ett färdigt koncept
              utvecklat, Butik, inredning, kassasystem, leverantörer och mycket
              annat.
            </p>
          </a>
          <StoreIcons>
            <a href="https://apps.apple.com/au/app/24-food/id1499978783?l=sv">
              <img
                src={appstoreicon}
                alt="Download on App Store"
                style={{ height: 46, marginRight: 10 }}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.it.food24&hl=en_US">
              <img
                src={googleplayicon}
                alt="Download on Google Play"
                style={{ height: 46 }}
              />
            </a>
          </StoreIcons>
        </FooterLeft>
        <FooterRight>
          <Uppercase>24food</Uppercase>
          Dialoggatan 12B | 703 74 Örebro
          <br />
          <a href="mailto:info@24food.se">info@24food.se</a>
          <br />
          <br />
          <span
            onClick={() => setShowGDPRInfo(true)}
            style={{ color: '#f7ac00', cursor: 'pointer' }}
          >
            Personuppgiftspolicy
          </span>
        </FooterRight>
        <p></p>
      </FooterDiv>
      {showGDPRInfo && (
        <GDPR>
          <div className="btn-close" onClick={() => setShowGDPRInfo(false)} />
          <h3>Introduktion</h3>
          <br />
          24Food är personuppgiftsansvarig och behandlar personuppgifter som
          registreras hos 24Food i samband med användning av applikationen. De
          personuppgifter som 24Food registrerar vid inloggning med mobiltbankID
          är namn och personnummer. Vid skapandet av konto registrerar 24Food
          namn, Id-handling och telefonnummer. <br />
          <br />
          <br />
          <h3>Personuppgiftsansvarig</h3>
          <br />
          24Food i Örebro AB Org. nummer: 559081–7788, är ansvarig för
          behandlingen av personuppgifter och ansvarar för att behandlingen av
          dina personuppgifter sker i enlighet med dataskyddslagstifning. <br />
          <br />
          Dina personuppgifter används av oss i följande syfte:
          <br />
          <br />
          För att ge dig möjligheten att handla i en 24Food butik.
          <br />
          För att kunna utveckla tjänsten.
          <br />
          För att fullgöra rättsliga förpliktelser
          <br />
          För att kunna anpassa information och erbjudanden.
          <br />
          <br />
          24Food kan komma att lämna ut personuppgifter till andra samarbetande
          företag för att kunna utveckla tjänsten och förebygga brottslighet.
          24Food kommer inte att sälja dina personuppgifter till extern part.
          24Food har vidtagit säkerhetsåtgärder för att förhindra att dina
          personuppgifter skall komma ut till externa parter. Förfrågningar om
          24Foods behandling av dina personuppgifter sker enligt
          kontaktuppgifterna nedan. Genom att acceptera villkoren samtycker du
          till behandlingen av personuppgifter som beskrivs i texten. 24Food
          kommer vid en av avinstallation av applikationen fortfarande lagra
          personlig information.
          <br />
          <br />
          <br />
          <h3>Marknadsföring</h3>
          <br />
          Applikationen används som en informationskanal för 24Foods kunder
          varigenom 24food kan skicka information och erbjudanden avseende sina
          och sina samarbetspartners produkter och tjänster. Genom att acceptera
          dessa villkor godkänner du att 24food skickar marknadsföring till dig.
          <br />
          <br />
          <br />
          <h3>Positionering</h3>
          <br />
          Applikationen kräver att du som användare samtycker till att du genom
          din enhet positioneras för vissa funktioner skall kunna användas. Du
          har möjlighet att godkänna eller neka detta. Ifall du inte vill att
          24Food skall använda sig av din platsinformation kan du stänga av
          denna i applikationen. Applikationen kommer då inte fungera.
          <br />
          <br />
          <br />
          <h3>Rättigheter enligt GDPR:</h3>
          <br />
          Dataskyddsförordningen ger registrerade personer en mängd rättigheter
          som din organisation (eller dina system) måste kunna hantera. De
          viktigaste rättigheterna handlar om kontroll över vilken information
          som får lagras och hur informationen får användas.
          <br />
          <br />
          Registrerades personer har enligt Dataskyddsförordningen GDPR rätt
          till:
          <br />
          <br />
          Information (om behandlingen)
          <br />
          Tillgång (till registrerad information)
          <br />
          Rättelse (av grunduppgifter)
          <br />
          Radering (rätt att bli struken, om det inte längre finns behov av
          uppgifterna)
          <br />
          Begränsning av behandling ("frys mina uppgifter!")
          <br />
          Dataportabilitet (rätt att få ut uppgifter på maskinläsbart format)
          <br />
          Att göra invändningar (=rätt att ifrågasätta användning av uppgifter.
          Exempel: rätt att slippa marknadsföring.)
          <br />
          Rättigheter kopplade till automatiserat beslutsfattande (framförallt:
          rätt att få information om automatiserad behandling)
          <br />
          <br />
          <br />
          <h3>Övrigt</h3>
          <br />
          Applikationen använder också olika typer av tjänster från Firebase.
          Dom tjänster 24food använder från Firebase är:
          <br />
          <br />
          Google analytics
          <br />
          Authentication
          <br />
          Firestore database
          <br />
          Realtime database
          <br />
          Storage
          <br />
          Functions
          <br />
          <br />
          Appen samlar också in kraschdata när någor fel sker i appen.
          <br />
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <span
              style={{ color: '#f7ac00', cursor: 'pointer' }}
              onClick={() => setShowGDPRInfo(false)}
            >
              Stäng fönster
            </span>
          </div>
        </GDPR>
      )}
    </FooterDivHolder>
  )
}

const StoreIcons = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`

const FooterDivHolder = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
`

const FooterDiv = styled.div`
  max-width: 980px;
  padding: 40px 0;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`

const FooterLeft = styled.div`
  width: 80%;
  text-align: left;
  margin-bottom: 40px;

  @media (min-width: 769px) {
    width: 50%;
    margin-bottom: 0;
  }
`

const FooterRight = styled.div`
  width: 80%;
  text-align: right;

  @media (min-width: 769px) {
    width: 50%;
  }
`

const Uppercase = styled.span`
  text-transform: uppercase;
  display: block;
  letter-spacing: 0.05em;
`

const GDPR = styled.div`
  /*display: none;*/
  position: fixed;
  top: 120px;
  left: calc(50% - 430px);
  bottom: 120px;
  width: 700px;
  max-width: 700px;
  padding: 80px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10000;

  .btn-close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    cursor: pointer;
  }
  .btn-close:hover {
    opacity: 1;
  }
  .btn-close:before,
  .btn-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;
  }
  .btn-close:before {
    transform: rotate(45deg);
  }
  .btn-close:after {
    transform: rotate(-45deg);
  }
`
