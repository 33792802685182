import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import stores from '../img/stores.jpg'

import { MainTextHolder } from './Utils'

export default function Partner() {
  const [showPartnerMain, setPartnerMain] = useState(true)
  const [showPartnerStore, setPartnerStore] = useState(false)
  const [showPartnerStart, setPartnerStart] = useState(false)
  const [showPartnerDelivery, setPartnerDelivery] = useState(false)

  const hideLayers = () => {
    setPartnerMain(false)
    setPartnerStore(false)
    setPartnerStart(false)
    setPartnerDelivery(false)
  }

  return (
    <PartnerHolder id="partner">
      <StyledMainTextHolder>
        <h3
          onClick={() => {
            hideLayers()
            setPartnerMain(true)
          }}
        >
          Samarbete
        </h3>
        <AnimatePresence>
          {showPartnerMain && <PartnerMain />}
          {showPartnerStore && <PartnerStore />}
          {showPartnerStart && <PartnerStart />}
          {showPartnerDelivery && <PartnerDelivery />}
        </AnimatePresence>
        <ButtonHolder>
          <StyledButton
            onClick={() => {
              hideLayers()
              setPartnerStore(true)
            }}
          >
            Vi behöver en butik
          </StyledButton>
          <Divider />
          <StyledButton
            onClick={() => {
              hideLayers()
              setPartnerStart(true)
            }}
          >
            Jag vill starta en 24food
          </StyledButton>
          <Divider />
          <StyledButton
            onClick={() => {
              hideLayers()
              setPartnerDelivery(true)
            }}
          >
            Leverantörer
          </StyledButton>
        </ButtonHolder>
      </StyledMainTextHolder>
    </PartnerHolder>
  )
}

const PartnerMain = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    style={{ position: 'relative' }}
  >
    <TextHolder>
      <div>
        <h3>Varför ska du samarbeta med oss?</h3>
        <p>
          Vår vision är att finnas där vi behövs. Vi har ett färdigt koncept
          utvecklat, Butik, inredning, kassasystem, leverantörer och mycket
          annat.
          <br />
          <br />
          Vi vill gärna dela vår vision med fler!
        </p>
      </div>
    </TextHolder>
  </motion.div>
)

const PartnerStore = () => (
  <SectionHolder
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <TextHolder>
      <div>
        <h3>Saknar ni en butik för livsmedel på Er ort?</h3>
        <p>
          Kontakta oss! Vi tar en titt på förutsättningarna just där du bor.
          Vill ni inte driva butiken själva så försöker vi hitta den bästa
          lösningen för alla! 24food vill erbjuda alla livsmedel och självklart
          dygnet runt!
          <br />
          <br />
          <h3>Småortskonceptet</h3>
          Ibland har 24Food inte möjlighet att starta en butik på orten. Då
          finns Småortskonceptet! Småortskonceptet är ett koncept där
          byn/bygdeföreningen arrenderar enheten gemensamt. I en by med 200
          invånare räcker de oftast med en engångsavgift på 600kr för en butik.
          Ni bestämmer helt och hållet prisbilden och typ av varor i butiken som
          rymmer ca 500 produkter.{' '}
          <a href="mailto:info@24food.se">Kontakta oss</a> redan idag så
          berättar vi mer!
        </p>
      </div>
    </TextHolder>
  </SectionHolder>
)

const PartnerStart = () => (
  <SectionHolder
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <TextHolder>
      <div>
        <h3>Vill du driva en 24food-butik?</h3>
        <p>
          Om du vill driva din egen 24food finns två tillvägagångssätt. Antingen
          kan du köpa en komplett butik direkt av oss eller så väljer du att
          hyra en av oss. Vi är marknadens billigaste samarbetspartners som
          erbjuder ett helhetskoncept inkl. butik, inredning, app och hemsida
          från 7395kr i månaden. Från kontraktsskrivning kan du få tillträde
          till din butik på under två månader.
          <br />
          <br />
          <a href="mailto:info@24food.se">Kontakta oss</a> redan idag och bli en
          del av 24Food, en av Sveriges snabbast växande rörelser.
        </p>
      </div>
    </TextHolder>
  </SectionHolder>
)

const PartnerDelivery = () => (
  <SectionHolder
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <TextHolder>
      <div>
        <h3>Vill du leverera varor till oss?</h3>
        <p>
          Om du har en produkt du skulle vilja testa att sälja i våra butiker
          finns goda möjligheter! 24food älskar lokalt producerade produkter,
          nyheter och allt som får plats i våra butiker!
        </p>
      </div>
    </TextHolder>
  </SectionHolder>
)

const PartnerHolder = styled.div`
  background-image: url(${stores});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 3;
  }
`

const StyledMainTextHolder = styled(MainTextHolder)`
  position: relative;
  z-index: 4;

  h3 {
    cursor: pointer;
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @media (min-width: 769px) {
      flex-direction: row;
      margin: 20px auto;
      justify-content: center;
    }
  }
`

const ButtonHolder = styled.div`
  @media (min-width: 769px) {
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 20px auto;
  }
`

const StyledButton = styled.button`
  background: transparent;
  border: 0;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 6px auto;
  padding: 10px;
  border-radius: 6px;

  @media (min-width: 769px) {
    width: auto;
    background-color: transparent;
    font-size: 24px;
  }
`

const Divider = styled.div`
  width: 1px;
  background-color: rgb(255, 255, 255);
  margin: 10px 0px;

  @media (max-width: 768px) {
    display: none;
  }
`

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 769px) {
    flex-direction: row;
    display: inline-block;
    align-items: center;
  }

  > div {
    margin: 0 auto;

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-top: 0;

      @media (min-width: 769px) {
        font-size: 26px;
        line-height: 46px;
      }
    }

    p {
      font-size: 18px;
      line-height: 24px;

      @media (min-width: 769px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }

  > img {
    width: 100%;

    @media (min-width: 769px) {
      width: 40%;
    }
  }

  h3 {
    text-align: left;
    font-size: 24px;
  }

  p {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin: 0;
  }
`

const SectionHolder = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${TextHolder} {
    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-top: 0;

      @media (min-width: 769px) {
        font-size: 26px;
        line-height: 46px;
      }
    }

    > div {
      margin-left: 0;

      p {
        font-size: 18px;
        line-height: 24px;

        @media (min-width: 769px) {
          font-size: 22px;
          line-height: 28px;
        }
      }
    }
  }
`
